<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.48747 2.1359C8.80297 1.9547 9.19703 1.9547 9.51253 2.1359L10.836 2.896C10.9653 2.97028 11.1106 3.01548 11.2608 3.02819L12.798 3.1583C13.1645 3.18932 13.4833 3.41115 13.6273 3.73535L14.2315 5.09533C14.2906 5.22823 14.3804 5.34656 14.4941 5.44141L15.6579 6.41204C15.9353 6.64341 16.0571 7.00235 15.9746 7.34572L15.6288 8.78611C15.595 8.92687 15.595 9.07313 15.6288 9.21389L15.9746 10.6543C16.0571 10.9976 15.9353 11.3566 15.6579 11.588L14.4941 12.5586C14.3804 12.6534 14.2906 12.7718 14.2315 12.9047L13.6273 14.2647C13.4833 14.5888 13.1645 14.8107 12.798 14.8417L11.2608 14.9718C11.1106 14.9845 10.9653 15.0297 10.836 15.104L9.51253 15.8641C9.19703 16.0453 8.80297 16.0453 8.48747 15.8641L7.16399 15.104C7.03465 15.0297 6.88941 14.9845 6.73919 14.9718L5.20197 14.8417C4.83553 14.8107 4.51672 14.5889 4.37268 14.2647L3.76846 12.9047C3.70941 12.7718 3.61965 12.6534 3.50592 12.5586L2.34213 11.588C2.06471 11.3566 1.94294 10.9976 2.02537 10.6543L2.3712 9.21389C2.405 9.07313 2.405 8.92687 2.3712 8.78611L2.02537 7.34572C1.94294 7.00235 2.06471 6.64342 2.34213 6.41204L3.50592 5.44141C3.61965 5.34656 3.70941 5.22823 3.76846 5.09533L4.37268 3.73535C4.51672 3.41115 4.83553 3.18932 5.20197 3.1583L6.73919 3.02819C6.88941 3.01548 7.03466 2.97028 7.16399 2.896L8.48747 2.1359Z"
      fill="#FAA53D" />
    <path
      d="M6.77964 8.45809L5.61616 7.98404C5.20388 7.81606 4.79416 8.2301 4.96646 8.64059L6.16667 11.5H11.8333L13.0335 8.64059C13.2058 8.2301 12.7961 7.81606 12.3838 7.98404L11.2204 8.45809C10.9897 8.55208 10.725 8.46193 10.5996 8.24668L9.43207 6.24189C9.2392 5.91073 8.7608 5.91073 8.56793 6.24189L7.40037 8.24668C7.27501 8.46193 7.01033 8.55208 6.77964 8.45809Z"
      fill="white" />
  </svg>
</template>
